.mat-chip {
    color: white !important;
    background: $primary-color !important;

    .mat-icon.prefix-icon {
        height: 16px;
        width: 16px;
        line-height: 16px;
        font-size: 16px;
        margin-right: 8px;
    }

    .mat-chip-remove {
        opacity: 0.9 !important;
        color: #ffffff !important;
    }
}

.outlined-chip {
    border: 1px solid #dddfe1;
    border-radius: 19px;
    background: white !important;
    color: $primary-color !important;
    padding: 7px 20px;
    font-size: 12px !important;
    line-height: 15px !important;

    &:not(:last-child) {
        margin-right: 8px;
    }
}
