.sieve-game-container {
    position: relative;

    img.sieve-logo {
        position: absolute;
        width: 142px;
        padding: 0 12px;
    }

    .sieve-tab-group {
        .mat-tab-header {
            // width: calc(100% - 240px);
            margin-left: 160px;
        }
    }
}

.preset-sieve-chip {
    padding-left: 5px !important;

    .mat-chipImg {
        height: 25px;
        width: 25px;
    }
}

.sieve-question-selection-list {
    .mat-list-item.mat-list-option {
        height: unset;
        margin-bottom: 14px;
        background-color: #f3f3f3;
        border-radius: 12px;

        &.mat-list-single-selected-option {
            background-color: #795548;
            color: antiquewhite;
        }

        .mat-list-item-content {
            padding: 12px 16px;
        }

        .question-details-container {
            display: flex;
            justify-content: space-around;
            margin-top: 16px !important;

            .question-details {
                text-align: center;

                .question-details-label {
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }
}


.virus-free-game-container {
    position: relative;

    img.virus-free-logo {
        position: absolute;
        width: 142px;
        padding: 0 12px;
    }

    .virus-free-tab-group {
        .mat-tab-header {
            // width: calc(100% - 240px);
            margin-left: 270px;
            margin-top: -55px;
        }
    }
}