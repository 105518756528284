@font-face {
  font-family: "Poppins";
  src: url(assets/fonts/Poppins-Regular.ttf) format("truetype");
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

$custom-typography: mat-typography-config($font-family: "Poppins, Roboto",
    $headline: mat-typography-level(32px, 48px, 700),
    $body-1: mat-typography-level(16px, 24px, 500),
  );

@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$biblist-app-primary: mat-palette($mat-indigo);
$biblist-app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$biblist-app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$biblist-app-theme: mat-light-theme((color: (primary: $biblist-app-primary,
        accent: $biblist-app-accent,
        warn: $biblist-app-warn,
      ),
    ));

$primary-color: #136cb6;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($biblist-app-theme);

/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/layout";
@import "./assets/styles/stepper";
@import "./assets/styles/card";
@import "./assets/styles/form/input";
@import "./assets/styles/form/checkbox";
@import "./assets/styles/table";
@import "./assets/styles/button";
@import "./assets/styles/chips";
@import "./assets/styles/preset-sieve";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-dialog-container {
  position: relative;

  .mat-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

a.link {
  font-size: 14px;
  color: #136cb6 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.selectable {
  cursor: pointer !important;
}

.ng5-slider .ng5-slider-pointer {
  outline: unset !important;
}

.details-verticle {
  display: flex;

  .content {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.audio-player {
  padding: 0 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  max-width: 416px;

  &.hidden {
    visibility: hidden;
    height: 0px;
    margin: unset !important;
  }
}

.actions-container {
  display: flex;
  padding-bottom: 36px;

  button {
    margin: 0 8px;
  }
}

.list-header-role {
  padding: 0 16px;
}

.ngx-p-1.play-pause.mat-button.mat-button-base {
  border-left: unset;
  min-width: unset !important;
}

.text-to-speech {
  display: flex;
  padding-bottom: 16px;

  &>* {
    margin: 0 8px;
  }
}

.details {
  &>.label {
    margin-bottom: 12px;
  }

  .content {
    font-size: 1rem;
    line-height: 1.2rem;
  }
}

.primary-color {
  color: $primary-color;
}

.outlined-section {
  border: 1px solid #dddfe1;
  border-radius: 6px;
  padding: 16px 30px;
}

.status-badge {
  padding: 4px 32px !important;
  border-radius: 7px !important;
  background: #b6b6b6;
  color: #ffffff;

  &.mat-button {
    min-width: unset !important;
    line-height: 22px;
  }

  &.status-a {
    background: #f2df24;
  }

  &.status-b {
    background: #ff4a64;
  }

  &.status-c {
    background: #724ee8;
  }

  &.status-d {
    background: #00e5ff;
  }

  &.status-e {
    background: #ff8c50;
  }

  &.status-f {
    background: #18f569;
  }
}

.question-id {
  color: #ff4747d8 !important;
}

.content.status {
  color: #18f569;
}

.question-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 4px 0px;

  .label {
    color: $primary-color !important;
  }

  .form-control {
    min-width: 156px;
  }
}

.text-primary {
  color: $primary-color !important;
}

.option-text {
  border: 2px solid #dddfe1;
  border-radius: 6px;
  padding: 16px;
  position: relative;
  padding-left: 62px;
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 17px;
  overflow: hidden;

  &.not-evaluated::after {
    content: "*";
    position: absolute;
    color: white;
    left: 26px;
    top: 2px;
    font-size: 22px;
    line-height: 27px;
  }

  &.correct {
    .label {
      background: #18f569;
    }
  }

  &.wrong {
    .label {
      background: #fc5062;
    }
  }

  &.u-correct {
    .label {
      background: #ffb547;
    }
  }

  .label {
    color: #ffffff;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    padding: 0px !important;
  }
}

.title {
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 600;
  color: $primary-color;
}

.category-list {
  display: flex;

  .category {
    display: flex;
    align-items: center;
    border: 1px solid #dddfe1;
    border-radius: 6px;
    padding: 3px 12px;

    &:not(:last-child) {
      margin-right: 16px;
    }

    .mat-icon {
      color: $primary-color !important;
      height: 16px;
      width: 16px;
      line-height: 16px;
      font-size: 16px;
      margin-right: 8px;
    }
  }
}

.filter-dialog-container {
  border-radius: 30px 30px 0 0 !important;
  padding-top: 24px !important;
  max-width: 590px !important;
  min-width: 590px !important;

  .mat-dialog-container {
    min-width: 750px;
  }
}

.notification-filter-dialog {
  max-width: 700px !important;
}

.outlined-text {
  border: 1px solid #dddfe1;
  border-radius: 6px;
  min-width: 80px;
  padding: 4px 4px;
  text-align: center;
  font-size: 12px;
  line-height: 1.2rem;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-b-4 {
  margin-bottom: 4px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.separator {
  flex: 1 1;
}

.search-filter {
  display: flex;

  .search-form {
    flex: 1 1;
  }

  .filter {
    .filter-button {
      margin-left: 8px;
    }
  }
}

.filter-chips-list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;

  .filter-label {
    padding: 0 8px;
  }
}

.name-column {
  min-width: 120px;
}

.caption {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;

  &.with-error {
    margin-bottom: 26px !important;

    .mat-error {
      right: 0px;
      left: unset !important;
    }
  }

  .mat-checkbox,
  .form-control {
    margin-left: 16px;
  }

  .form-control {
    width: 90px;
    min-width: 90px;
  }

  .form-group {
    margin-bottom: unset !important;
  }
}

.clear-filter {
  text-align: right;
  padding: 0 8px;
  padding-bottom: 8px;
}

.option-sort-dialog {
  width: 540px;
}

.dialog-with-progress {
  .mat-dialog-container {
    position: relative;

    .mat-progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .action {
      padding-top: 24px;
      padding-bottom: 16px;
    }
  }
}

.add-tag-dialog,
.edit-tag-dialog {
  min-width: 364px;

  .mat-dialog-content {
    max-height: 75vh !important;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #136cb6;
}

.mat-tab-body-content {
  padding-top: 24px;
  padding-left: 8px;
  padding-right: 8px;
}

.tab-icon {
  margin-right: 8px;
}

.tooltip-spinner.mat-progress-spinner,
.tooltip-spinner.mat-spinner {
  margin-left: 8px;

  circle {
    stroke: #d7d7d7;
  }
}

.tag-tooltip {
  max-width: 60vw;
}

.result-container {
  display: flex;
  align-items: center;
  padding: 0 0.75rem !important;

  .result-img>img {
    width: 36px;
    height: 36px;
  }
}

.mat-expansion-panel.option-text-container {
  margin-bottom: 16px !important;
}

.setBackground {
  background: transparent !important;
}

.direction_arrow {
  filter: drop-shadow(0px 3px 4px #0000004d);
  position: absolute;
  bottom: 24px;
  left: 20px;
}

.direction_arrow img {
  transition: 0.225s;
}

.direction_arrow .direction1 img {
  transform: rotate(0deg);
}

.direction_arrow .direction2 img {
  transform: rotate(60deg);
}

.direction_arrow .direction3 img {
  transform: rotate(120deg);
}

.direction_arrow .direction4 img {
  transform: rotate(180deg);
}

.direction_arrow .direction5 img {
  transform: rotate(240deg);
}

.direction_arrow .direction6 img {
  transform: rotate(300deg);
}

.direction_arrow.dashboard-btn {
  bottom: unset;
  left: unset;
  top: 60px;
  right: 79px;
  z-index: 10;

  .arrow_img {
    width: 55px;
    height: 50px;
    background: #fca82f;
    color: white;

    .mat-icon-button {
      line-height: unset;
    }

    .mat-button-wrapper {
      font-size: 8px;
    }
  }
}

.arrow_img {
  position: absolute;
  clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
  width: 50px;
  height: 46px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

:host::ng-deep.optionHex.selectedHex .hexinner {
  background-color: #fff;
  box-shadow: inset 0px 2px 28px #00000073;
}

.hex.selectedHex {
  background-color: #fca82f !important;
  z-index: 2 !important;

  .hexinner {
    margin: 3px;
    cursor: pointer;
  }
}

.optionHex.selectedHex:not(.currentHex) {
  .hexinner {
    box-shadow: inset 5px 4px 12px #0c0c0c54;
  }
}

.hex:not(.selectedHex) {
  background: #84a1c3;
}

.hex.disconnectedCells {
  background-color: red !important;
  z-index: 2 !important;
}

.flex_end {
  display: flex;
  justify-content: end;
}

.question-tag {
  .correct {
      background: green !important;
      color: white !important;
      border-bottom: 1px solid white;

      .chip_icon {
        color: white !important;
      }
  }

  .question {
      // background: #136cb6 !important;
      background: #0e5d9f !important;
      color: white !important;
      border-bottom: 1px solid white;

      .chip_icon {
        color: white !important;
      }
  }

  .wrong {
      background: #eb1010f0 !important;
      color: white !important;
      border-bottom: 1px solid white;

      .chip_icon {
        color: white !important;
      }
  }
}

.radio-btn {
  display: flex;
  gap: 15px;
}