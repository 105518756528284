.label {
    font-size: 0.8rem;
    line-height: 1.1rem;
    font-weight: 600;
    // font-weight: 500;
    color: #000000;
    padding-right: 12px;
}

label {
    margin-bottom: unset !important;
    font-size: 0.7rem;
    line-height: 0.9rem;
}

.search-form {
    position: relative;

    .mat-icon.search-icon {
        position: absolute;
        right: 16px;
        top: 8px;
        color: $primary-color;
    }
}

.form-control {
    border: 1px solid #dddfe1;
    border-radius: 6px;
    // min-width: 156px;
    width: 100%;
    font-size: 0.8rem;

    &.search {
        border: 2px solid #136cb6;
        border-radius: 29px;
        padding-right: 46px;
        padding-left: 24px;

        &:focus {
            box-shadow: unset !important;
        }
    }

    &input,
    &.mat-select {
        height: 32px;
    }

    &.mat-select {
        padding-right: 0px;
        position: relative;

        &.mat-select-disabled {
            &:focus {
                box-shadow: unset;
                border-color: unset;
                outline: unset;
            }

            .mat-select-trigger .mat-select-arrow-wrapper {
                background: #79afdc;
            }
        }

        .mat-select-trigger {
            position: unset !important;
            width: calc(100% - 36px);
            .mat-select-value {
                max-width: unset !important;
            }
            .mat-select-arrow-wrapper {
                background: #136cb6;
                width: 26px;
                height: 29px;
                display: flex;
                justify-content: center;
                position: absolute;
                align-items: center;
                top: 0px;
                right: 1px;
                border-radius: 6px;

                .mat-select-arrow {
                    color: #ffffff !important;
                }
            }
        }
    }
}

.form-group {
    margin-bottom: 0.6rem;
    line-height: 16px;
    .label {
        padding-bottom: 4px;
    }
}

.form-group {
    position: relative;

    &.with-suffix {
        position: relative;

        .form-control {
            padding-right: 36px;
        }

        .suffix {
            color: $primary-color;
            position: absolute;
            right: 8px;
            bottom: 7px;
        }
    }

    &.verticle-align {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-control {
            max-width: 58px;
        }
    }

    &.with-prefix {
        position: relative;

        .form-control {
            padding-right: 36px;
        }

        .prefix {
            color: $primary-color;
            position: absolute;
            left: 8px;
            bottom: 7px;
        }
    }

    .date-range-input {
        border: 1px solid #dddfe1;
        border-radius: 6px;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 8px;
        height: 32px;
        font-size: 0.7rem;
        line-height: 0.9rem;
    }

    .form-control-group {
        &.contact-number {
            position: relative;
            padding-left: 104px;
            .country-code {
                position: absolute;
                width: 94px;
                left: 0;
                top: 0;
            }
        }
    }

    .max-number-hint {
        position: absolute;
        right: 16px;
        font-size: 14px;
        line-height: 19px;
        top: 100%;
    }

    .mat-error {
        font-size: 10px;
        line-height: 13px;
        position: absolute;
        left: 0;
        top: calc(100%);
    }
}

.with-icon {
    position: relative;

    .form-control {
        padding-right: 20px;
    }

    .suffix {
        color: $primary-color;
        position: absolute;
        right: 5px;
        bottom: 7px;
    }
}

.with-error {
    position: relative;
    margin-bottom: 1rem;

    .mat-error {
        font-size: 10px;
        line-height: 13px;
        position: absolute;
        left: 0;
        top: calc(100%);
    }
}
