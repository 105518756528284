button:focus {
    outline: none !important;
}

button.rounded {
    border-radius: 23px !important;
}

button.outline {
    border: 2px solid;
    overflow: hidden;

    &.mat-primary {
        border-color: $primary-color !important;
        color: $primary-color !important;
    }
}

button {
    transition: padding-right 225ms;
}

button.loading-btn {
    padding-right: 36px;

    .mat-spinner {
        position: absolute;
        right: 4px;
        top: 4px;
    }
}

.mat-raised-button:not(.fit),
.mat-button:not(.fit) {
    min-width: 140px !important;
}

.mat-raised-button.mat-primary:not(:disabled) {
    background-color: $primary-color;
}

.mat-icon-button.social {
    .mat-icon {
        height: 40px;
        width: 40px;
        line-height: 40px;
    }
}

button[matdialogclose] {
    position: absolute;
    top: 12px;
    right: 12px;
}
