.app-layout {
    height: 100%;
    position: relative;

    .app-progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;

        .mat-progress-bar-fill::after {
            background-color: $primary-color;
        }
    }

    .logo-container {
        background: white;
        border: 2px solid #f5f5f5;
        border-radius: 63px;
        margin: 24px;
        margin-bottom: 0px;
        padding: 0px 8px;

        img {
            width: 186px;
        }
    }

    .mat-toolbar {
        background-color: white;
        height: 90px;

        .logo img {
            height: 76px;
            margin: 0 38px;
        }
    }

    .mat-drawer-container {
        height: 100%;
        .app-container {
            position: relative;
            min-height: 100%;
            height: 100%;

            .body {
                padding-top: 16px;
                padding-bottom: 16px;
                height: 100%;
                // overflow: auto;

                .card-outlined {
                    min-height: 100%;
                    margin-bottom: 16px;
                }
            }
        }

        .footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2.5rem;
            // background: $primary-color;
            // color: white;
        }

        .mat-drawer {
            min-width: 230px;
            background: $primary-color;
            z-index: 1;
            border: unset !important;

            .mat-drawer-inner-container {
                overflow-x: hidden;
                .mat-nav-list {
                    margin-left: 36px !important;

                    .sidenav-link {
                        margin: 12px 0;

                        .sub-nav {
                            margin-left: 0px !important;
                            margin-right: 16px !important;
                            .sub-link {
                                margin-top: 16px;
                                border-radius: 24px;
                                overflow: hidden;
                            }
                        }
                    }
                }
            }

            /* width */
            .mat-drawer-inner-container::-webkit-scrollbar {
                width: 0px;
            }

            /* Track */
            .mat-drawer-inner-container::-webkit-scrollbar-track {
                background: #2e3192;
            }

            /* Handle */
            .mat-drawer-inner-container::-webkit-scrollbar-thumb {
                background: #c5c5c5;
            }

            /* Handle on hover */
            .mat-drawer-inner-container::-webkit-scrollbar-thumb:hover {
                background: #ababab;
            }

            .mat-list-item {
                color: white;
                border-radius: 24px 0px 0px 24px;
                // font-size: 25px;
                // line-height: 38px;

                .mat-ripple {
                    border-radius: 24px 0px 0px 24px;
                    display: none;
                }
                .mat-icon {
                    margin-right: 12px;
                }
            }

            .mat-list-item.active {
                background: #fafafa;
                color: $primary-color !important;
                // overflow: hidden;
                position: relative;

                &::after {
                    position: absolute;
                    width: 36px;
                    height: 36px;
                    content: " ";
                    z-index: -1;
                    top: -36px;
                    right: 0px;
                    background: radial-gradient(200% 200% at top left, #136cb6 50%, #fafafa 50%);
                }

                &::before {
                    position: absolute;
                    width: 36px;
                    height: 36px;
                    content: " ";
                    z-index: -1;
                    bottom: -36px;
                    right: 0px;
                    background: radial-gradient(200% 200% at bottom left, #136cb6 50%, #fafafa 50%);
                }
            }

            .sidenav-link {
                .mat-expansion-panel {
                    box-shadow: unset !important;
                    background: transparent;

                    .mat-expansion-panel-body {
                        padding: 0 0 0 24px;

                        .mat-list-base {
                            padding-top: 0px;
                        }
                    }
                }

                .mat-expansion-panel-spacing {
                    margin: 0px 0;
                }
            }
        }
    }

    .top-nav-link {
        height: 100%;
        border-radius: 0px;
        display: flex;
        align-items: center;
        color: #505050;
        img.avatar {
            border: 0.5px solid #80808042;
            height: 62px;
            border-radius: 50%;
            margin-left: 12px;
        }
    }
}

.seperator {
    flex: 1 1;
}
