.checkbox-outlined {
    border: 2px solid #dddfe1;
    border-radius: 6px;
    padding: 6px;
    display: flex;
    align-items: center;
    height: 32px !important;

    .mat-list-item-content {
        height: 28px !important;
        padding: 0 6px !important;
    }

    .mat-icon {
        height: 16px;
        width: 16px;
        line-height: 16px;
        font-size: 16px;
        margin-right: 10px;
        margin-left: 4px;
        color: $primary-color;
    }

    .text {
        white-space: nowrap;
        padding-left: 8px !important;
        font-size: 14px !important;
    }

    .mat-list-text {
        flex-direction: row !important;
        align-items: center !important;
        padding-right: 8px !important;
    }

    .mat-list-item-ripple {
        display: none;
    }

    .mat-checkbox-inner-container {
        margin-left: 16px;
        margin-right: 4px;
    }

    .mat-checkbox-layout {
        flex-direction: row-reverse !important;
        margin-bottom: 1px !important;
    }
}

.mat-selection-list.row {
    display: flex !important;
    padding-top: unset !important;
}

.mat-checkbox {
    .mat-checkbox-inner-container,
    .mat-checkbox-frame,
    .mat-checkbox-background {
        height: 20px;
        width: 20px;
    }

    &.mat-checkbox-checked.mat-primary .mat-checkbox-background {
        background-color: #136cb6;
    }

    .mat-checkbox-frame {
        border: unset !important;
        background-color: #dfdfdf;
    }
}

.mat-pseudo-checkbox {
    background-color: #dfdfdf !important;
    border: unset !important;
    height: 16px !important;
    width: 16px !important;
}

.mat-pseudo-checkbox-checked::after {
    top: 3.4px !important;
    left: 2px !important;
    width: 9px !important;
    height: 4px !important;
}

.mat-primary .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background-color: #136cb6 !important;

    &.mat-pseudo-checkbox-checked::after {
        top: 3.4px !important;
        left: 2px !important;
        width: 9px !important;
        height: 4px !important;
    }
}

.mat-primary .mat-pseudo-checkbox.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background-color: #136cb6 !important;
}

.mat-checkbox.invert {
    .mat-checkbox-layout {
        flex-direction: row-reverse;

        .mat-checkbox-inner-container {
            margin-right: 0px;
            margin-left: 8px;
        }
    }
}
