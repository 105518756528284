.card-outlined {
    background: #ffffff;
    border: 2px solid #f5f5f5;
    border-radius: 10px;
    padding: 16px;
}

.mat-card {
    box-shadow: 12px 12px 30px #00000012 !important;
    border-radius: 18px !important;
}
