.hexmap {
	box-sizing: border-box;
	display: block;
	transform: scale(1);
	position: relative;
	max-width: 100%;
}
.hexmapinner {
	list-style: none;
	padding: 0px;
	margin: 0px;
	transform-origin: 50% 50%;
	width:100%;
	height:100%
}
.hex {
	box-sizing: border-box;
	position: absolute;
	background: #11407A;
	margin: 0;
	z-index: 1;
}
.hex:hover, .hex:focus { z-index: 2; }
.hexmap.pointy .hex {
	width: 4em !important;
	height: 4.9282em !important;
}
.hexmap.flat .hex {
	width: 3.7em !important;
	height: 3.47em !important;
}
.hexinner {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	margin: 1px;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	text-decoration: none; 
}
.hexmap.pointy .hex, .hexmap.pointy .hexinner {
	-webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
	clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}
.hexmap.flat .hex, .hexmap.flat .hexinner {
	-webkit-clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
	clip-path: polygon(25% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0 50%);
}
.hexcontent { padding: 0.25em; font-size: 0.8em; line-height: 1.2em; color: black;}
.when-selected { display: none;}
.hex:hover .when-selected, .hex:focus .when-selected { display: block; font-size: 1em;; }
.hex .when-selected .value { font-size: 2em; font-weight: 700; }
.hex:hover .default, .hex:hover .no-hover, .hex:focus .default, .hex:focus .no-hover { display: none; }

/* Fudge for IE */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.hexmap.pointy .hex, .hexmap.pointy .hexinner { border-radius: 80% / 50%; }
	.hexmap.flat .hex, .hexmap.flat .hexinner { border-radius: 50% / 80%; }
}
/* Fudge for Edge (and other browsers that support @supports but not clip-path */
@supports not (clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)) {
	.hexmap.pointy .hex, .hexmap.pointy .hexinner { border-radius: 80% / 50%; }
	.hexmap.flat .hex, .hexmap.flat .hexinner { border-radius: 50% / 80%; }
}

 .hex:hover {
	/* background-color: #fca82e !important; */
}
 .hex:hover .hexinner, .hex:focus .hexinner {
    color: black;
    /* margin: 3px; */
    cursor: pointer;
}